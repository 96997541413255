import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as headerStyles from './header.module.scss'
import MenuLinks from './menuitems';
import MyMenu from './menu'





const Header = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
  `)

  

    return (
      <header className={headerStyles.header}>


        <nav className={headerStyles.nav}>
          <div className={headerStyles.sidemenu}>
          <MyMenu pageWrapId={"page-wrap"} outerContainerId={"App"}>
                  <MenuLinks />
          </MyMenu>
        </div>

        <div className={headerStyles.topmenu}>
         <MenuLinks/>
         </div>
         {/* {<a className={headerStyles.clickbg} href="/" ><div></div></a>} */}
         <Link className={headerStyles.clickbg}  activeClassName={headerStyles.activeNavItem}  to = "/"></Link>
        </nav>
 
      </header>
    )
  }
  
  export default Header
  