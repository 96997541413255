import React, { Fragment } from 'react'
import Footer from "./footer"
import Header from "./header"


import * as layoutStyles from './layout.module.scss'
import CookieConsent from "react-cookie-consent";
import "../styles/index.css"
import "../styles/menu.css"




const Layout = (props) => {
   let featureImage='';

    if (props.FeatureImg) {
        featureImage=  <img className={`"featureImage"`} src={props.FeatureImg} alt="coverimage" />
    } 
    
    return (
        <Fragment>
        <div className={layoutStyles.container}>
            <Header />
        
            {featureImage}
           
            <div className={layoutStyles.content}>
            
                {props.children}
            </div>

        </div>

        <Footer />

<CookieConsent
    enableDeclineButton
  location="bottom"
  buttonText="Accept"
  declineButtonText="Decline"
  cookieName="gatsby-gdpr-google-analytics">
   This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.

If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.
</CookieConsent>
        </Fragment>
    )
  }
  
  export default Layout
  