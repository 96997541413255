import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as headerStyles from './header.module.scss'

const MenuItems = (props) => {
    return (
      <div className={headerStyles.navList}>

        <Link className={headerStyles.navItem}  activeClassName={headerStyles.activeNavItem}  to = "/">Home</Link>


        <Link className={headerStyles.navItem}  activeClassName={headerStyles.activeNavItem}  to = "/blog">Blog</Link>



        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/latinmottogenerator">Motto generator</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/LatinQuotes/LatQuotes_a">Latin Quotes</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/LatinMaxims/LegalQuotes_a">Legal Maxims</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/latinderivatives">Derivatives</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/italianphrases">Italian</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/latinphilosophyterms">Philosophy</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/latinbibleverses">Bible Verses</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/sadquotes">Death Quotes</Link>
        <Link className={headerStyles.push} className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to = "/frenchmottos">French Mottos</Link>
    </div>
    )
}

export default MenuItems