import React from "react";
import * as footerStyles from './footer.module.scss';
import { Link } from 'gatsby';

const Footer = () => {
     const currentYear=new Date().getFullYear();
    return (
     <div>
     
<footer className={footerStyles.footer}>  
  
 <p className={footerStyles.footerText}>
 Copyright &copy; 2003-{currentYear} InRebus.com. All Rights Reserved.
</p>
<p className={footerStyles.privacyText}><Link to = "/privacy">Privacy</Link>
</p>

</footer>

     </div>
    )
  }
  
  export default Footer
  